<template>
  <div>
    <v-container class="login d-flex flex-column justify-center align-center">
        <v-form class="d-flex flex-column align-center" @submit.prevent="login">
          <v-img :src="require('../assets/logo.png')" alt="CircleUp" width="120"></v-img>
          <v-text-field
              v-model="email"
              label="Email"
              type="email"
              required
          ></v-text-field>
          <v-text-field
              v-model="password"
              label="Password"
              type="password"
              required
          ></v-text-field>
          <v-btn :disabled="email === '' || password === ''" color="primary" class="align-self-end" type="submit">Login</v-btn>
        </v-form>
    </v-container>
    <v-snackbar v-model="snackbar" timeout="2000" color="error">
      Login failed, try again
    </v-snackbar>
  </div>
</template>

<script>
export default {
  name: "Login",
  data: () => ({
    email: '',
    password: '',
    snackbar: false
  }),
  methods: {
    login() {
      this.$store.dispatch('authenticate', {email: this.email, password: this.password}).then(() => {
        this.$router.push('/dashboard')
      }).catch(() => {
        this.snackbar = true
      })
    }
  }
}
</script>

<style scoped lang="scss">
.login {
  height: 100vh;

  p {
    color: rgba(100, 109, 130, 0.5) !important;
  }
}

.logo {
  max-width: 300px;
}
</style>